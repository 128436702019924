$(function () {
    $('*[name="phone"]').mask('+7(000)000-00-00');



    $("formp[name='first-visit']").submit(function (e) {
        e.preventDefault();
    });

    $("formp[name='first-visit']").validate({
        submitHandler: function (form, event) {
            ajax_main(form);
            return false;
        },
        rules: {
            name: {required: true},
            phone: {required: true, minlength: 6, maxlength: 11, number: true},
        },
        messages: {
            name: {required: "Укажите имя"},
            phone: {
                required: "Поле обязательное для заполнения",
                number: "Номер телефона должен состоять только из цифр",
                minlength: "Телефон должен содержать не менее 6цифр",
                maxlength: "Телефон должен содержать не более 11цифр",
            },
        }
    });

});


jQuery.extend(jQuery.validator.messages, {
    required: "Поле обязательное для заполнения",
    remote: "Please fix this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).his field is re",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    accept: "Please enter a value with a valid extension.",
    maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    minlength: jQuery.validator.format("Please enter at least {0} characters."),
    rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
});